<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="name">
              <div style="display: inline-block" class="divSpan">名称：</div>
              <div style="display: inline-block">
                <el-input
                  placeholder="请输入搜索的名称"
                  v-model.trim="queryinfo.name"
                  clearable
                  @clear="getList"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="seachChange"
                  ></el-button>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form>

        <el-col :span="10">
          <el-button type="primary" icon="el-icon-circle-plus" @click="AddTool"
            >新增产品</el-button
          >
          <el-button type="info" icon="el-icon-edit" @click="UpdateType"
            >修改类型</el-button
          >
        </el-col>
      </el-row>
      <el-row> </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ToolList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            label="类型"
            min-width="100px"
            prop="type"
          ></el-table-column>
          <el-table-column
            prop="brief"
            label="描述"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="url"
            label="页面地址"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="owner_name"
            label="用户"
            min-width="100px"
          ></el-table-column>
          <el-table-column label="状态" min-width="100px">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{
                scope.row.status == 1 ? "已启用" : "已禁用"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="300px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="Edits(scope.row)"
                >修改</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="VersionManger(scope.row)"
                >版本管理</el-button
              >
              <el-button
                :type="scope.row.status == 1 ? 'danger' : 'success'"
                size="mini"
                style="margin-left: 10px"
                @click="Forbidden(scope.row)"
                >{{ scope.row.status == 1 ? "禁用" : "启用" }}
              </el-button>
              <!-- 
              <el-button type="info" size="mini" @click="Edits(scope.row)"
                >上移</el-button
              >
              <el-button type="info" size="mini" @click="Edits(scope.row)"
                >下移</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!--  -->
      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        :visible.sync="createDialogVisible"
        width="35%"
        @close="createDialogClosed"
      >
        <el-form
          :model="createForm"
          :rules="createFormRules"
          ref="createFormRef"
          label-width="100px"
        >
          <el-form-item label="产品归属：" prop="belong">
            <ELselect
              v-model="createForm.belong"
              :options="Types"
              :optionKey="ToolOptionKeys1"
              Splaceholder="请选择"
            ></ELselect>
          </el-form-item>
          <el-form-item label="产品类型：" prop="type" v-show="!EditOP">
            <el-input
              v-model.trim="createForm.type"
              placeholder="请输入类型名称，唯一标识"
            ></el-input>
          </el-form-item>
          <el-form-item label="中文名称：" prop="name">
            <el-input
              v-model.trim="createForm.name"
              placeholder="请输入中文名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="英文名称：" prop="enName">
            <el-input
              v-model.trim="createForm.enName"
              placeholder="请输入英文名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="跳转地址：" prop="detailUrl">
            <el-input
              v-model.trim="createForm.detailUrl"
              placeholder="请输入跳转地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示详情：" prop="hide" style="text-align: left">
            <el-radio-group v-model="createForm.hide">
              <el-radio
                v-for="item in radioList"
                :key="item.menuIndex"
                :label="item.menuIndex"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品描述：" prop="describe">
            <el-input
              type="textarea"
              clearable
              rows="6"
              placeholder="请输入产品描述"
              v-model.trim="createForm.describe"
              maxlength="200"
              @input="DesText"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="产品图标："
            prop="logoImage.icon"
            :rules="createFormRules.toolIcon"
          >
            <UploadOneIcon :iconInfo="createForm.logoImage"></UploadOneIcon>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmAddTool">保存</el-button>
        </span>
      </el-dialog>

      <!--  -->
      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        :visible.sync="CreatetypeDialogVisible"
        width="35%"
        @close="CreatetypeDialogClosed"
      >
        <el-form
          :model="CreatetypeForm"
          :rules="CreatetypeFormRules"
          ref="CreatetypeFormRef"
          label-width="100px"
        >
          <el-form-item label="旧名称：" prop="id">
            <ELselect
              v-model="CreatetypeForm.id"
              :options="ToolOptions"
              :optionKey="ToolOptionKeys1"
              Splaceholder="请选择"
            ></ELselect>
          </el-form-item>
          <el-form-item label="新名称：" prop="name">
            <el-input
              v-model.trim="CreatetypeForm.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="CreatetypeDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmUpdateType">保存</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>

import ELselect from '@/components/myself/el_select.vue'
import { toolTypeList, submitToolVersion, updateToolType, toolListPage, updateToolStatus, getTool, updateToolVersion } from '@/api/toolmanager/toolVersion.js'
import UploadOneIcon from '@/components/myself/UploadCommon/UploadOne.vue'
import { validatorInput } from '@/components/myself/speciaChaFilt.js'

export default {
  components: { ELselect, UploadOneIcon },
  data () {
    return {
      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      ToolList: [],
      createDialogVisible: false,
      createForm: {
        type: '',
        name: '',
        describe: '',
        logoImage: {
          icon: '',
          iconSize: '',
          IconObject: {},
          acceptImage: ".png,.jpg,.gif,.bmp",
          type: 'tool-icon'
        },
        detailUrl: '',
        enName: '',

        hide: 0,
        belong: '',
      },
      createFormRules: {
        type: [
          { required: true, message: '类型不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
        ],
        name: [
          { required: true, message: '中文名称不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        // enName: [
        //   { required: true, message: '英文名称不能为空', trigger: 'blur' },
        //   { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        // ],
        detailUrl: [
          { required: true, message: '跳转地址不能为空', trigger: 'blur' },
        ],
        describe: [
          { required: true, message: '描述不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
        ],
        toolIcon: [
          { required: true, message: '图标不能为空', trigger: 'blur' }
        ],
        hide: [
          { required: true, message: '标志不能为空', trigger: 'blur' }
        ],
        belong: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
      },
      ToolOptions: [],
      ToolOptionKeys1: {
        value: 'id',
        label: 'name',
        label2: '',
      },
      wordnum: 0,

      uploadSuccess: false,
      loading: false,
      titleName: '',


      typeDialogVisible: false,
      CreatetypeDialogVisible: false,

      CreatetypeForm: {
        id: '',
        name: ''
      },
      CreatetypeFormRules: {
        id: [
          { required: true, message: '类型不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ]
      },

      EditOP: false,
      toolID: '',

      radioList: [
        { menuIndex: 0, name: '是' },
        { menuIndex: 1, name: '否' },
      ],
      Types: [
        {
          id: 1,
          name: '数智软件'
        }, {
          id: 2,
          name: '智能硬件'
        }],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await toolListPage(this.queryinfo.name, this.queryinfo.pageNum, this.queryinfo.pageSize)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ToolList = res.data.data.list
      this.total = res.data.data.total
      // console.log('77',res.data.data.list)
    },
    async TypeList () {
      const res = await toolTypeList()
      if (res.status !== 200) return this.$message.error('添加失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      if (this.createDialogVisible) {
        this.ToolOptions = res.data.data.filter(item => item.status !== 1)
      } else {
        this.ToolOptions = res.data.data
      }
    },
    AddTool () {
      this.titleName = '新增产品'
      this.createDialogVisible = true
      this.EditOP = false
      this.TypeList()

    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()
    },
    DesText () {
      this.wordnum = this.createForm.describe.length
    },

    confirmAddTool () {
      this.$refs.createFormRef.validate(async valid => {
        if (valid) {
          if (!this.EditOP) {
            let quiese = { brief: this.createForm.describe, name: this.createForm.name, enName: this.createForm.enName, detailUrl: this.createForm.detailUrl, type: this.createForm.type, toolIcon: this.createForm.logoImage.IconObject, hide: this.createForm.hide, mold: this.createForm.belong }
            const res = await submitToolVersion(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          } else {
            let quiese = {}
            if (this.createForm.logoImage.iconSize.length === 0) {
              quiese = { id: this.toolID, brief: this.createForm.describe, name: this.createForm.name, enName: this.createForm.enName, detailUrl: this.createForm.detailUrl, type: this.createForm.type, hide: this.createForm.hide, mold: this.createForm.belong }
            } else {
              quiese = { id: this.toolID, brief: this.createForm.describe, name: this.createForm.name, enName: this.createForm.enName, detailUrl: this.createForm.detailUrl, type: this.createForm.type, toolIcon: this.createForm.logoImage.IconObject, hide: this.createForm.hide, mold: this.createForm.belong }
            }
            // console.log('888888',quiese)
            const res = await updateToolVersion(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          }
          this.$message.success('操作成功')
          this.createDialogVisible = false
          this.EditOP = false
          this.getList()
        }
      })
    },
    UpdateType () {
      this.CreatetypeDialogVisible = true
      this.titleName = "修改产品类型"
      this.TypeList()
    },
    CreatetypeDialogClosed () {
      this.$refs.CreatetypeFormRef.resetFields()
    },
    confirmUpdateType () {
      this.$refs.CreatetypeFormRef.validate(async valid => {
        if (valid) {
          const res = await updateToolType(this.CreatetypeForm)
          if (res.status !== 200) return this.$message.error('修改失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('修改类型成功')
          this.CreatetypeDialogVisible = false
          this.getList()
        }
      })
    },

    // 
    async Edits (para) {
      this.titleName = '修改产品'
      this.createDialogVisible = true
      this.EditOP = true
      this.toolID = para.id
      const res = await getTool(para.id)
      if (res.status !== 200) return this.$message.error('获取详情失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      // console.log('77777', res.data.data)
      this.createForm.type = res.data.data.toolBase.type
      this.createForm.name = res.data.data.toolBase.name
      this.createForm.enName = res.data.data.toolBase.enName
      this.createForm.hide = res.data.data.toolBase.hide
      this.createForm.belong = res.data.data.toolBase.mold
      this.createForm.detailUrl = res.data.data.detailUrl
      this.createForm.describe = res.data.data.toolBase.brief
      this.createForm.logoImage.icon = res.data.data.url

      this.createForm.toolIconSize = ''

    },

    VersionManger (para) {
      this.$router.push({ path: '/versionManage', query: { id: para.id } })
    },
    async Forbidden (para) {
      const res = await updateToolStatus(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.getList()
    },

    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

  }
}
</script>
